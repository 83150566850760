import React from "react";

import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import DeleteIcon from "@mui/icons-material/Delete";
import AspectRatioOutlined from "@mui/icons-material/AspectRatioOutlined";
import AddIcon from "@mui/icons-material/Add";

import capitalize from "../../utils/capitalize";
import getDefaultMenu from "../../components/Post/menu";

import defaultPostTypeConfig from "../post";
import Content from "./Content";
import Edit from "./Edit";
import New from "./New";

import setContents from "./contents";
import getConstants from "./constants";

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    title: { type: String },
                    content: { type: String },
                },
                ...(p.config) ? p.config : {}
            },
        })
    },
    getConstants: getConstants,
    setContents: setContents,
    requestForUserPage: async function requestForUserPage(p = {}) {
        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name:"configurator"
        })
    },
    getComponent: function (p) {

        const name = "configurator";
        const ns = (name.endsWith("y")) ? name.slice(0,-1)+"ies" : name+"s";

        const {context, appContext} = p;
        const {routes, /*userStatusManager*/} = appContext;

        const {res, req} = context;
        const route = res.wappResponse.route;
        const {requestPath, params} = route;
        const user = req.wappRequest.user;

        const isAdmin = user && user._status_isFeatured;

        const pages = {
            content: Content,
            edit: Edit,
            new: New
        };

        if ((requestPath.startsWith(routes[name+"Route"])) || (requestPath.startsWith(routes[ns+"Route"]))){

            if (requestPath.startsWith(routes[ns+"Route"]) && !isAdmin) {
                return null;
            }

            if ((requestPath.startsWith(routes[name+"Route"]))){

                function getMenu({embedPath = "", appContext, statusManager, ...rest}) {

                    const embedMenu = {
                        href:(p) => "/embed/"+p.post?._id + embedPath,
                        name: appContext.labels.embedPageLabel,
                        Icon: AspectRatioIcon,
                        role: function embedMenuRole(p) {
                            const isAdmin = p.user && p.user[appContext.userStatusManager._status_isFeatured];
                            const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                            const isBanned = p.post && p.post[statusManager._status_isBanned];
                            if ((isBanned && !isAdmin)){
                                return false;
                            }
                            if (isAdmin || isAuthor) {
                                return !!(p.post?._id && p.page !== "new" && p.page !== "edit");
                            }
                            return p.post && p.post[statusManager._status_isNotDeleted];
                        },
                    }

                    return [
                        embedMenu,
                        ...getDefaultMenu({appContext, statusManager, ...rest}).map((m)=>{delete m.featured; return m;}),
                    ]
                }

                return defaultPostTypeConfig.getComponent({
                    ...p,
                    name,
                    archiveComponentProps:{
                        type: (isAdmin) ? "table" : "card",
                        getMenu,
                    },
                    componentProps:{
                        pages,
                        layoutType: "article",
                        showAuthor: ({user})=>user?._status_isFeatured,
                        showCreatedDate: ({user, author})=>user?._id && user._id === author?._id,
                        getMenu: (p)=>getMenu({
                            embedPath: "/"+"/:page/:innerPage1/:innerPage2/:innerPage3"
                                .split("/:")
                                .filter((key)=>params[key])
                                .map((key)=>params[key])
                                .join("/"),
                            ...p
                        })
                    }
                });

            }
        }
    },
    userPageFunctions: function (p) {

        const {context, /*appContext, */postContext} = p;

        const n = "configurator";
        const ns = (n.endsWith("y")) ? n.slice(0,-1)+"ies" : n+"s";
        const N = capitalize(n);
        const Ns = capitalize(ns);

        const r = defaultPostTypeConfig.userPageFunctions({...p, name:n});

        const {res} = context;

        const wappResponse = res.wappResponse;
        const route = wappResponse.route;
        const {params} = route;
        const {pageType} = params;

        //const {userStatusManager} = appContext;

        function addMenuItems(props) {

            const {appContext, /*statusManager*/} = props;
            const {menus, routes, /*userStatusManager*/} = appContext;

            return [
                {
                    name: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        return (isAuthor) ? menus["my"+Ns+"Menu"] : menus[postContext.name+Ns+"Menu"];
                    },
                    href: function (p) {
                        return (p.post?._id) ? "/"+p.post._id + routes[postContext.name+Ns+"Route"] : routes[postContext.name+Ns+"Route"];
                    },
                    role: function (p) {
                        const isAdmin = p.user && p.user._status_isFeatured;
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        return (isAdmin || isAuthor);
                    },
                    Icon: AspectRatioIcon,
                    order: 25,
                },
                {
                    name: function () {
                        return menus["deleted"+Ns+"Menu"];
                    },
                    href: function (p) {
                        return (p.post?._id) ? "/"+p.post._id + routes[postContext.name+Ns+"Route"]+"/deleted" : routes[postContext.name+Ns+"Route"]+"/deleted"
                    },
                    role: function (p) {
                        const isAdmin = p.user && p.user._status_isFeatured;
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAuthorOrAdmin = (isAdmin || isAuthor);
                        const isPostsPage = ((p.page === ns && !p.pageType) || (p.page === ns && !isNaN(Number(p.pageType)) && Number(pageType) >= 1 ));
                        return (isPostsPage && isAuthorOrAdmin);
                    },
                    Icon: DeleteIcon,
                    onlyIcon: true,
                    featured: true,
                },
            ];
        }

        function addContentMenuItems(props) {

            const {appContext, /*statusManager*/} = props;
            const {menus, routes, /*userStatusManager*/} = appContext;

            return [
                {
                    name: menus["new"+N+"Menu"],
                    href: routes[n+"Route"] + "/new",
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        return !!(isAuthor);
                    },
                    Icon: AddIcon,
                    disableParentRoute: true,
                    order: 25,
                },
                {
                    name: function (p) {
                        const isAuthor = ((p?.user?._id && p?.user?._id === p?.post?._author) || (p?.user?._id && p?.user?._id === p?.post?._author?._id));
                        return (isAuthor) ? menus["my"+Ns+"Menu"] : menus[postContext.name+Ns+"Menu"];
                    },
                    href: function (p) {
                        return (p?.post?._id) ? "/"+p.post._id + routes[postContext.name+Ns+"Route"] : routes[postContext.name+Ns+"Route"];
                    },
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return ((!p.post?._status_isFeatured && !isAuthor && isAdmin) || (isAuthor && !isAdmin));
                    },
                    Icon: AspectRatioIcon,
                    order: 26,
                },
            ]
        }

        function getPageName({user, post, page, /*statusManager*/}) {

            const isAdmin = user && user._status_isFeatured;
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id));
            const isAuthorOrAdmin = (isAdmin || isAuthor);

            return ((page === ns && !pageType) || (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1 ) || (page === ns && pageType === "deleted" && isAuthorOrAdmin)) ? (isAuthorOrAdmin) ? page : "notFound" : null;

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName
        }
    },
    adminMenu: function (p) {
        const name = "configurator";
        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({...p, name});
        defaultAdminMenu.Icon = AspectRatioIcon;
        defaultAdminMenu.order = 2;
        defaultAdminMenu.items[0].Icon = AspectRatioOutlined;
        return defaultAdminMenu;
    }
};

export default postTypeConfig;
