import React, {useContext, useState, useEffect} from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import getUtils from "wapplr-react/dist/common/Wapp/getUtils";
import {WappContext} from "wapplr-react/dist/common/Wapp";
import AppContext from "../../../components/App/context";
import PostContext from "../../../components/Post/context";

import Content from "../Content";
import style from "./style.css";

export default function EmbedComponent(props){

    const {appContext, name="configurator", subscribe, userPostTypeName = "user"} = props;
    const context = useContext(WappContext);
    const utils = getUtils(context);

    const {wapp} = context;

    wapp.styles.use(style);

    const [url, setUrl] = useState(utils.getRequestUrl());
    const [user, setUser] = useState(utils.getRequestUser());

    useEffect(function (){

        function onUserChange(user){
            setUser((user?._id) ? user : null);
        }

        const unsub = subscribe.userChange(onUserChange);
        return function useUnsubscribe(){
            unsub();
        }
    }, [subscribe, user]);

    useEffect(function (){

        function onLocationChange(newUrl){
            if (url !== newUrl){
                setUrl(newUrl);
            }
        }

        const unsub = subscribe.locationChange(onLocationChange);
        return function useUnsubscribe(){
            unsub();
        }
    }, [subscribe, url]);

    const userStatusManager = wapp.getTargetObject().postTypes.findPostType({name: userPostTypeName}).statusManager;
    const post = utils.getGlobalState().res.responses && utils.getGlobalState().res.responses[name+"FindById"];

    return (
        <AppContext.Provider value={{userStatusManager, ...appContext}}>
            <PostContext.Provider value={{user, post}}>
                <Content
                    {...props}
                    justContent={true}
                    externalSettings={{
                        position:"fixed",
                        fullPage: true,
                        getTopMenu: ({context})=> [{
                            name: appContext.labels.showInTheFrameworkLabel,
                            onClick: (e) => {
                                const page = context.res.wappResponse.route.requestPath || "";
                                const href = (post) ? appContext.routes[name+"Route"] + "/"+post._id + page : appContext.routes[name+"Route"];
                                wapp.client.history.push({search:"", hash:"", pathname: href});
                                e.preventDefault();
                            },
                            disableParentRoute: true,
                            Icon: ArrowBackIcon
                        }]
                    }}
                />
            </PostContext.Provider>
        </AppContext.Provider>
    )
}
