import React, {useContext, useEffect, useState, useRef} from 'react';

import {WappContext, withWapp} from 'wapplr-react/dist/common/Wapp';
import getUtils from 'wapplr-react/dist/common/Wapp/getUtils';
import clsx from 'clsx';

import {withMaterialStyles} from '../../../components/Template/withMaterial';
import PostContext from '../../../components/Post/context';
import ExternalContext from '../../../components/App/externalContext';
import AppContext from '../../../components/App/context';

import materialStyle from './materialStyle';
import style from './style.css';

function Content(props) {

    const context = useContext(WappContext);
    const postContext = useContext(PostContext);
    const appContext = useContext(AppContext);
    const {name = "configurator", post} = postContext;

    const {wapp, req, res} = context;
    const utils = getUtils(context);

    const {routes} = appContext;

    wapp.styles.use(style);

    const {
        subscribe,
        //materialStyle,
        justContent,
        externalSettings = {},
    } = props;

    const configurator = res.wappResponse["boligkonfigurator-app"];
    const {store, appStateName = "APP_STATE"} = configurator.res.wappResponse;
    const state = store.getState();
    const stateText = `if (!window["${appStateName}"] || window["${appStateName}"] && !window["${appStateName}"].req){ window["${appStateName}"] = ${JSON.stringify(state)} }`;

    const [Configurator, setConfigurator] = useState(configurator.Render);
    const [url, setUrl] = useState(utils.getRequestUrl());

    wapp.styles.use({
        _getCss: function () {
            if (wapp.target === "node") {
                const css = configurator.wapp.styles.getCssText();
                return css[0] && css[0].cssText || "";
            }
            return "";
        }
    });

    useEffect(function () {
        const unsub = subscribe.locationChange(function onLocationChange(newUrl) {

            if (newUrl !== url) {

                const configurator = res.wappResponse["boligkonfigurator-app"];

                if (configurator.update && !configurator.updated) {
                    configurator.update();
                    configurator.updated = true;
                }

                if (configurator.Render && configurator.Render !== Configurator) {
                    setConfigurator(configurator.Render)
                }

                setUrl(newUrl);

            }

        });
        return function useUnsubscribe(){
            unsub();
        }
    }, [url])

    if (configurator.res.wappResponse.statusCode === 404){
        res.wappResponse.status(404)
    }

    const {params} = res.wappResponse.route;
    const innerPath = "/"+"/:page/:innerPage1/:innerPage2/:innerPage3".split("/:").filter((key)=>params[key]).map((key)=>params[key]).join("/");
    const parentPath = req.wappRequest.path.split(innerPath)[0];

    const container = useRef();

    useEffect(()=>{
        if (wapp.target === "web") {
            const configurator = res.wappResponse["boligkonfigurator-app"];
            configurator.res.container = container.current;
            configurator.res.wappResponse.container = container.current;
        }
    }, [container])

    return (
        <ExternalContext.Provider value={{externalSettings: {
            ...externalSettings,
                embedUrl:  req.wappRequest.protocol+"://"+req.wappRequest.hostname + routes[name+"Route"]+"/embed/"+post?._id,
                parentUrl: req.wappRequest.protocol+"://"+req.wappRequest.hostname + parentPath
        }}}>
            {
                (justContent && Configurator) ?
                    <div
                        ref={container}
                        className={clsx({[style.heightByParent]: externalSettings.heightByParent})}
                        id={configurator.wapp.getTargetObject().config.containerElementId}>
                        <script dangerouslySetInnerHTML={{__html: stateText}}/>
                        {(Configurator) ? Configurator : null}
                    </div>
                    :
                    <div className={clsx(style.post, {[style.heightByParent]: externalSettings.heightByParent})}>
                        <div
                            ref={container}
                            className={clsx(style.content, {[style.heightByParent]: externalSettings.heightByParent})}
                            id={configurator.wapp.getTargetObject().config.containerElementId}>
                            <script dangerouslySetInnerHTML={{__html: stateText}}/>
                            {(Configurator) ? Configurator : null}
                        </div>
                    </div>
            }
        </ExternalContext.Provider>
    )
}

const WappComponent = withWapp(Content);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
