// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HJmUh{}\n\n.MGPKf{\n    border-style: solid;\n    border-width: 1px;\n    border-color: var(--border-color);\n    border-radius: 5px;\n    overflow: hidden;\n}\n\n.H8CB1 {\n    height: 100%;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post": "HJmUh",
	"content": "MGPKf",
	"heightByParent": "H8CB1"
};
module.exports = ___CSS_LOADER_EXPORT___;
