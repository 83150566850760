import defaultPostTypeConfig from "../post";
import capitalize from "../../utils/capitalize";

export default function getConstants(p = {}) {

    const name = "configurator";
    const N = capitalize(name);

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    });

    return {
        labels: {
            ...labels,
            showInTheFrameworkLabel: "Show it in the framework",
            embedPageLabel: "Embed page",
        },
        menus: {
            ...menus,
        },
        messages: {
            ...messages,
        },
        routes: {
            ...routes,
        },
        titles: {
            ...titles,
        }
    }
}
