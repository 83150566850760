import React, {useContext, useRef} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import AppContext from "../../../components/App/context";
import {withMaterialStyles} from "../../../components/Template/withMaterial";
import PostContext from "../../../components/Post/context";
import Form, {defaultComponents} from "../../../components/Form";
import SchemaForm from "../../../components/SchemaForm";
// noinspection ES6UnusedImports
import defaultAppData from "boligkonfigurator-app/dist/common/defaultAppData";

import materialStyle from "./materialStyle";
import style from "./style.css";

import getSchemaForSchemaForm from "./schema";
import capitalize from "../../../utils/capitalize";
import {postTypesConfig} from "../../index";

function getFormDataForParentForm({utils, postContext, appContext, innerPage1}) {

    const {name, post} = postContext;

    const N = capitalize(name);

    let formDataFromResolvers = {};
    try {
        formDataFromResolvers = utils.getGlobalState("res.graphql.mutation."+name+"Save.formData");
    } catch (e){
        console.log(e)
    }

    const isNotDeleted = post && post._status_isNotDeleted;

    const formData = {
        ...formDataFromResolvers,
        submit: {
            label: (isNotDeleted) ? appContext.labels["save"+N+"SubmitLabel"] : appContext.labels["restore"+N+"SubmitLabel"]
        }
    };

    if (post?._id && formData._id){
        formData._id.value = post;
        formData._id.disabled = true;
        formData._id.refPostType = name;
    }

    if (innerPage1 !== "freetext") {
        if (formData["record.title"]) {
            formData["record.title"].hidden = true;
            formData["record.title"].disabled = true;
        }
    }

    Object.keys(formData).forEach((key)=>{
        if (formData[key].componentName?.startsWith("Posts") && formData[key].enableNew || formData[key].schemaType === "MongoID" && formData[key].enableNew ){
            const refPostType = formData[key].refPostType;
            formData[key].NewComponent = postTypesConfig[refPostType] && postTypesConfig[refPostType].getPages ? postTypesConfig[refPostType].getPages().new : null;
        }
    });

    if (post?._id) {
        Object.keys(formData).forEach(function (key) {
            if (key.startsWith("record.")) {
                const ka = key.split(".");
                let value = post;
                ka.forEach(function (nk) {
                    if (nk !== "record"){
                        if ((value && value[nk]) || (value && value[nk] === 0)){
                            value = value[nk];
                        } else {
                            value = null;
                        }
                    }
                });
                if (value || value === 0){
                    formData[key].value = value;
                }
            }
        })
    }

    return formData;

}

function Edit() {

    const postContext = useContext(PostContext);
    const {post, name, parentRoute} = postContext;

    const N = capitalize(name);

    const context = useContext(WappContext);
    const {wapp, req, res} = context;
    const appContext = useContext(AppContext);
    const utils = getUtils(context);

    wapp.styles.use(style);

    if (wapp.globals.DEV){
        //post.content = JSON.stringify(defaultAppData);
    }

    const query = req.wappRequest.query;

    const wappResponse = res.wappResponse;
    const route = wappResponse.route;
    const {params} = route;
    const {innerPage1} = params;

    const schemaFormForm = useRef();

    let postContent = {};
    try {
        postContent = JSON.parse(post.content)
    } catch (e){}

    const formSettingsForParent = getFormDataForParentForm({utils, postContext, appContext, innerPage1});

    const schemaForSchemaForm = getSchemaForSchemaForm({data: postContent});

    if (innerPage1 !== "freetext") {
        formSettingsForParent["record.content"].componentName = "SchemaForm";
        formSettingsForParent["record.content"].data = postContent;
        formSettingsForParent["record.content"].schema = schemaForSchemaForm;
    }

    async function onSubmit(e, data) {

        if (innerPage1 !== "freetext") {

            const isValid = schemaFormForm.current.isValid();
            const schemaFieldResponse = await schemaFormForm.current.onSubmit(e);
            const schemaFieldData = schemaFieldResponse.data;
            const schemaFieldError = schemaFieldResponse.error;

            if (schemaFieldError || !isValid) {
                return;
            }

            const languages = schemaFieldData.settings?.basic_settings?.languages || ["en_us"];

            const args = {
                ...data,
                record: {
                    ...data.record,
                    content: JSON.stringify({
                        ...schemaFieldData
                    }),
                    title: schemaFieldData.settings?.basic_settings?.name[languages[0]] || data.record.title || "My configurator"
                }
            }

            return await utils.sendRequest({
                requestName: name + "Save",
                args,
                redirect: {pathname: query.redirect || parentRoute + "/" + post._id, search: "", hash: ""},
                timeOut: 1000
            });

        }

        return await utils.sendRequest({requestName: name+"Save", args: data, redirect: {pathname: query.redirect || parentRoute+"/"+post._id, search:"", hash:""}, timeOut:1000 });

    }

    const customFormComponents = {
        SchemaForm: {
            Component: React.memo(SchemaForm, (p, n)=>{
                return p.data === n.data
            }),
            props: {
                schema: {},
                data: {},
                ref: (e)=>{schemaFormForm.current = e;},
                storageName: (post?._id) ? "SchemaForm_"+post._id : "SchemaForm",
                FormComponent: (props)=> <div className={props.className}>{props.children}</div>,
                onSubmit: async (e, {data, error})=>{
                    return {data, error};
                },
                additionalProperties: {
                    generateSchema: ({schemaFormContext}) => {
                        return getSchemaForSchemaForm({data: schemaFormContext.rootData});
                    }
                }
            }
        },
    }

    return (
        <Form
            formData={formSettingsForParent}
            onSubmit={onSubmit}
            successMessage={
                appContext.messages["save"+N+"SuccessMessage"]
            }
            components={{
                ...defaultComponents,
                ...customFormComponents
            }}
            initialState={(state) =>{
                return {
                    ...state,
                    formData: {
                        ...state.formData,
                        ["record.content"]: {
                            ...state.formData["record.content"],
                            schema: schemaForSchemaForm
                        }
                    }
                }
            }}
        />
    )
}

const WappComponent = withWapp(Edit);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
